<template>
  <section class="custom-width d-flex flex-column px-3">
    <table :id="`actions`" style="table-layout: auto">
      <thead>
        <tr>
          <th class="border px-4 py-3 d-flex align-items-center">
            <span
              class="text-center text-capitalize font-weight-bolder font-size-h5"
              style="flex-basis: 80%"
            >
              actions</span
            >
          </th>
        </tr>
      </thead>
      <tbody :class="`actions-tb`">
        <template
          v-if="
            !Boolean(
              $store.getters.getSCANCONFIGTEMPLATESelectedTemplateDetails
                ?.showForm
            )
          "
        >
          <tr>
            <td class="border px-4 py-2">Select a field</td>
          </tr>
        </template>
        <div
          v-else
          class="d-flex flex-column w-100 py-4 px-6 border border-top-0"
        >
          <div class="d-flex align-items-center w-100 mb-6">
            <div
              class="form-check form-check-sm form-check-custom form-check-solid me-2 min-w-20px min-h-20px"
            >
              <input
                :disabled="Boolean(formData.is_master_scan === null)"
                class="form-check-input"
                type="checkbox"
                v-model="formData.is_master_scan"
              />
            </div>
            <span class="font-size-lg font-weight-medium ml-2">
              Master scan
            </span>
          </div>

          <div class="d-flex align-items-center w-100 mb-6">
            <div
              class="form-check form-check-sm form-check-custom form-check-solid me-2 min-w-20px min-h-20px"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="formData.is_required"
              />
            </div>
            <span class="font-size-lg font-weight-medium ml-2"> Required </span>
          </div>

          <div class="d-flex align-items-center w-100 mb-6">
            <div
              class="form-check form-check-sm form-check-custom form-check-solid me-2 min-w-20px min-h-20px"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="formData.is_editable"
              />
            </div>
            <span class="font-size-lg font-weight-medium ml-2"> Editable </span>
          </div>

          <div class="d-flex align-items-center w-100 mb-8">
            <div
              class="form-check form-check-sm form-check-custom form-check-solid me-2 min-w-20px min-h-20px"
            >
              <input
                :disabled="Boolean(formData.is_parseable === null)"
                class="form-check-input"
                type="checkbox"
                v-model="formData.is_parseable"
              />
            </div>
            <span class="font-size-lg font-weight-medium ml-2">
              Parsable
            </span>
          </div>

          <div class="d-flex align-items-center w-100 mb-6">
            <div
              class="form-check form-check-sm form-check-custom form-check-solid me-2 min-w-20px min-h-20px"
            >
              <input
                class="form-check-input"
                type="checkbox"
                v-model="formData.is_persist_input_value"
              />
            </div>
            <span class="font-size-lg font-weight-medium ml-2">
              Persist input value
            </span>
          </div>

          <div class="d-block h-85px w-100 mb-2">
            <div class="mb-2">
              <span class="font-size-lg font-weight-medium">
                Default value
              </span>
            </div>

            <v-text-field
              :disabled="Boolean(formData.default_value === null)"
              v-model="formData.default_value"
              outlined
              dense
            />
          </div>

          <div class="d-block h-85px w-100">
            <div class="mb-2">
              <span class="font-size-lg font-weight-medium">
                Display order
              </span>
            </div>

            <v-text-field
              v-model.number="$v.formData.display_order.$model"
              type="number"
              clearable
              outlined
              dense
              :error-messages="display_orderErrors"
              @blur="$v.formData.display_order.$touch()"
            />
          </div>
        </div>
      </tbody>
    </table>
  </section>
</template>

<script>
import scanningConfigurationTemplates from "@/own/mixins/scanningConfigurationTemplatesMixin";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "NestedForm",
  mixins: [scanningConfigurationTemplates, validationMixin],
  // mixins: [scanningConfigurationTemplates],
  validations: {
    formData: {
      display_order: { required },
    },
  },
  data: () => ({
    formData: {
      index: null,
      field_name: null,
      // field_title: null,
      field_type: null,
      is_master_scan: null,

      is_required: null,
      is_editable: null,
      is_parseable: null,
      is_persist_input_value: null,
      default_value: null,
      display_order: null,
    },
  }),
  mounted() {
    this.$v.formData.display_order.$touch();
  },
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    initForm(selectedField, template_index) {
      const foundIndex = this.$store.getters.getSCANCONFIGTEMPLATETemplates[
        template_index
      ].fields.findIndex((field) => field.field_name === selectedField.id);

      const { is_master_scan, is_parseable, default_value } =
        this.$store.getters.getSCANCONFIGTEMPLATETemplates[template_index]
          .fields[foundIndex];

      this.formData =
        this.$store.getters.getSCANCONFIGTEMPLATETemplates[
          template_index
        ].fields[foundIndex];

      this.formData.is_master_scan = selectedField.is_allowed_for_master_scan
        ? is_master_scan
        : null;

      this.formData.is_parseable = selectedField.is_allowed_for_parsing
        ? is_parseable
        : null;

      this.formData.default_value = selectedField.is_default_value_allowed
        ? default_value
        : null;
    },
  },
  computed: {
    display_orderErrors: function() {
      return this.handleFormValidation("display_order");
    },
  },
};
</script>

<style scoped>
.custom-width {
  width: 100%;
}

@media (min-width: 769px) {
  .custom-width:nth-child(3) {
    width: 40%;
  }

  .custom-width {
    width: 30%;
  }
}
</style>
